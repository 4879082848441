import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "video-question-wrapper font-size-19" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "practice-header bg-color-apple-675 px-5 py-3 fsc-block text-white font-weight-bold" }
const _hoisted_4 = { class: "d-flex justify-content-between" }
const _hoisted_5 = { class: "bg-color-apple-150 practice-content px-5 pt-0 pb-3 fsc-block" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheoryPreExamsAnswer = _resolveComponent("TheoryPreExamsAnswer")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedQuestion && _ctx.selectedQuestion.id && _ctx.showAnswer)
      ? (_openBlock(), _createBlock(_component_TheoryPreExamsAnswer, {
          key: 0,
          question: _ctx.selectedQuestion,
          onClose: _ctx.onAnswerClose
        }, null, 8, ["question", "onClose"]))
      : _createCommentVNode("", true),
    (!_ctx.showAnswer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("general.evaluation_for", { user: _ctx.userName })), 1)
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_b_row, { class: "pt-3 font-weight-bold" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, {
                  sm: "6",
                  class: "d-flex flex-column justify-content-center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.exam_class")) + " ", 1),
                      (_ctx.theoryPreExamLicenseClass)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.theoryPreExamLicenseClass), 1))
                        : _createCommentVNode("", true),
                      _createTextVNode(" - " + _toDisplayString(_ctx.points.totalPoints) + " " + _toDisplayString(_ctx.$t("general.error_points")), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, { sm: "1" }),
                _createVNode(_component_b_col, { sm: "5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["border-1-solid-white", _ctx.theoryPreExamsAnswerSuccess ? 'bg-color-apple-675' : 'bg-monza-500']),
                              style: {"height":"40px"}
                            }, null, 2)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_b_col, { class: "d-flex flex-column justify-content-center text-uppercase" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.theoryPreExamsAnswerSuccess ? _ctx.$t("general.passed") : _ctx.$t("general.not_passed")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { class: "bg-curious-blue-500" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.basic_material")) + " - " + _toDisplayString(_ctx.points.incorrectBasicQuestionPoints) + " " + _toDisplayString(_ctx.$t("general.error_points")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedTheoryPreExamQuestions.basic, (paragraph, index) => {
              return (_openBlock(), _createBlock(_component_b_row, {
                sm: "6",
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { class: "d-flex flex-column justify-content-center" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(paragraph.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_b_col, {
                    sm: "1",
                    class: "d-flex flex-column justify-content-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(paragraph.countCorrect) + "/" + _toDisplayString(paragraph.answersStatus.length), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_b_col, { sm: "5" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paragraph.answersStatus, (answer, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            style: _normalizeStyle([{"height":"40px"}, _ctx.answersByParagraphColumnWidthStyle(_ctx.groupedTheoryPreExamQuestions.basic)]),
                            class: _normalizeClass(["border-1-solid-white pointer", answer.correct ? 'bg-color-apple-675' : 'bg-monza-500']),
                            key: index,
                            onClick: ($event: any) => (_ctx.selectQuestion(answer.id, 0))
                          }, null, 14, _hoisted_8))
                        }), 128))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { class: "bg-curious-blue-500" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("calendar.class")) + " " + _toDisplayString(_ctx.theoryPreExamLicenseClass) + " - " + _toDisplayString(_ctx.points.incorrectSpecificQuestionPoints) + " " + _toDisplayString(_ctx.$t("general.error_points")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedTheoryPreExamQuestions.specific, (paragraph, index) => {
              return (_openBlock(), _createBlock(_component_b_row, {
                sm: "6",
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { class: "d-flex flex-column justify-content-center" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(paragraph.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_b_col, {
                    sm: "1",
                    class: "d-flex flex-column justify-content-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(paragraph.countCorrect) + "/" + _toDisplayString(paragraph.answersStatus.length), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_b_col, { sm: "5" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paragraph.answersStatus, (answer, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            style: _normalizeStyle([{"height":"40px"}, _ctx.answersByParagraphColumnWidthStyle(_ctx.groupedTheoryPreExamQuestions.specific)]),
                            class: _normalizeClass(["border-1-solid-white pointer", answer.correct ? 'bg-color-apple-675' : 'bg-monza-500']),
                            key: index,
                            onClick: ($event: any) => (_ctx.selectQuestion(answer.id, 1))
                          }, null, 14, _hoisted_10))
                        }), 128))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}